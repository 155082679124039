@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Roboto:wght@400;700;900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --primary-font-family:'Roboto', sans-serif;
  --secondary-font-family:'Inter', sans-serif;
  --primary-color:#196E88;
  --secondary-color:#32A4C8;
}
a{
  text-decoration: none;
  color: #fff;
}

li{
  list-style: none;
}

h2{
  color: var(--primary-color);
  font-family: var(--primary-font-family);
  font-weight: 800;
  font-size: 46px;
}

.btn:hover{
  color: #fff;
}

.header{
 
  background-color: var(--primary-color);
  /* padding: 0 15% 0 10%; */
}


.nav-link{
  color: #fff !important;
  font-family: var(--secondary-font-family);
  /* font-size: 16px; */
  font-weight: 500;
}

.carousel-item{
  height: 80vh;
}

.hero h1{
  display: flex;
  color: #fff;
  font-family: var(--primary-font-family);
  font-weight: 900;
  padding: 4.8% 0 9.5% 0;
  font-size: 54px;
  line-height: 1.4;
}

.about-us-home{
  line-height: 28px;
  font-weight: 500;
  font-family: var(--secondary-font-family);
}

.about-us-home button{
  background-color: var(--secondary-color);
  color: #fff;
}
.gallery-img{
  
  height: 310px;
  image-rendering: pixelated;
}
.gallery-img img{
  height: 100%;
}


.vision{
  background-color: rgba(241, 239, 239, 0.9);
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(24, 24, 24, 0.9);
}

.vision p {
  font-family: var(--secondary-font-family);
  font-size: 24px;
  font-weight: 700;
  color: var(--secondary-color);
}

.border-line{
  border: 4px solid #69858e;
  border-radius: 10px;
  width: 20%;
  display: block;
  margin: auto;
}

#objectives li, #psp li{
  list-style: disc;
  font-size: 18px;
  font-family: var(--secondary-font-family);
  font-weight: 500;
}
.card-title{
  color: var(--primary-color);
  font-family: var(--primary-font-family);
  font-weight: 700;
  font-size: 36px;
}

.contact-info{
  display: flex;
  margin: auto;
  justify-content: flex-start;
  align-items: center;
  gap: 2em;
  font-family: var(--secondary-font-family);
  font-weight: 700;
  font-size: 24px;
  padding-left: 14%;
}

.icon{
  border: 2px solid var(--primary-color);
  border-radius: 60%;
  padding: 0 25px;

}
.contact-info .icon i{
  font-size: 6rem;
  color: var(--primary-color);
}
.bank-details .contact-info h3{
  font-family: var(--secondary-font-family);
  font-weight: 700;
  font-size: 24px;
}
.bank-details .contact-info p{
  font-weight: 500;
  font-size: 18px;
}

.project a{
  color: black;
}
.project-details p{
  font-size: 20px;
  font-family: var(--primary-font-family);
}

.team nav .nav .nav-link{
  color: var(--primary-color) !important;
}
/* footer */

.footer{
  background-color: var(--primary-color);
  color: #fff;
}

.footer button{
  background-color: var(--secondary-color);
  color: #fff;
  
}
.accordion-body a{
  color: var(--secondary-color);
}

.project-list  li{
  font-size: 18px;
  border-bottom: 1px solid #222222;
}

.project-list li:hover{
 color: var(--secondary-color);
  transform: scale(1.02);
  border-bottom: 1px solid var(--secondary-color);
  transition: ease 0.8s;
}
.project-list li:hover a{
  color: var(--secondary-color);
}
@keyframes scroll {
	0% { transform: translateX(0); }
	100% { transform: translateX(calc(-250px * 10))}
}


.slider {
	background: white;
	box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
	height: 100px;
	margin: auto;
	overflow:hidden;
	position: relative;
	width: 70%;

}

.slider::before,
.slider::after {
  background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  content: "";
  height: 100px;
  position: absolute;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  animation: scroll 25s linear infinite;
  display: flex;
  width: calc(250px * 20);
}
.slider .slide {
  height: 100px;
  width: 250px;
}
@media only screen and (max-width: 600px) {
  .contact-info {
    padding-left: 0;
    font-size: 20px;
  }
}

.noCont{
  height: 60vh;
  font-size: 54px;
  color: #69858e;
  position: relative;
}
.center-div{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}